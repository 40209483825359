<template>
    <div class="login-pop">
        <div class="login-con">
            <div class="close" @click="close"></div>
            <div class="login-register-form">
                <!--                会员登录-->
                <template v-if="show === 1">
                    <div class="title">
                        <h3>会员登录</h3>
                        <p>
							<a class="mar-10" href="javascript:;" @click="gocodelogin('loginForm')">验证码登录</a>
						</p>
                    </div>
                    <el-form label-position="top" ref="loginForm" :model="loginForm" :rules="loginRules" label-width="60px">

                        <input v-if="!checked" type="password" class="inputhide"/>
                        <input v-if="!checked" type="text" class="inputhide"/>
                        <el-form-item label="用户名" prop="account">
                            <div class="con">
                                <el-input v-model="loginForm.account" placeholder="邮箱或手机号码" clearable></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <div class="con">
                                <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" clearable show-password></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="">
                            <div class="con" style="display: flex;justify-content: space-between;">
                                <el-checkbox label="" v-model="checked">记住登录名及密码</el-checkbox>
                                <a href="javascript:;" style="color: #ea6100;" @click="retpass">忘记密码</a>
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="btn-group">
                        <div class="btn" @click="login(1)">立即登录</div>
                        <div class="btn white" @click="close">取消</div>
                    </div>
					<div class="display justify-spb gozc mat10">
						<div></div>
						<a href="#/register" @click="close" class="c-f37500"><span class="c-999">还没有账号？</span>去注册</a>
					</div>
                </template>
                <!--                验证码登录-->
                <template v-if="show === 2">
                    <div class="title">
                        <h3>会员登录</h3>
                        <p>
							<a class="mar-10" href="javascript:;" @click="show = 1">账号密码登录</a>
						</p>
                    </div>
                    <el-form label-position="top" ref="loginForm1" :model="loginForm1" :rules="loginRules1" label-width="60px">
                      <input type="password" class="inputhide" id="loginPassword"/>
                      <input type="text" class="inputhide" id="loginUserName"/>
                      <el-form-item label="用户名" prop="account">
                            <div class="con">
                                <el-input v-model="loginForm1.account" placeholder="邮箱或手机号码"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="验证码" prop="code">
                            <div class="con">
                                <el-input type="number" v-model="loginForm1.code" placeholder="请填写验证码"></el-input>
                                <div class="get-code" @click="getCode(loginForm1.account,1)">{{ getSmsCodeText }}</div>
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="btn-group">
                        <div class="btn" @click="codelogin">立即登录</div>
                        <div class="btn white" @click="close">取消</div>
                    </div>
					<div class="display justify-spb gozc mat10">
						<div></div>
						<a href="#/register" @click="close" class="c-f37500"><span class="c-999">还没有账号？</span>去注册</a>
					</div>
                </template>
                <!--                忘记密码-->
                <template v-if="show === 3">
                    <div class="title">
                        <h3>找回密码</h3>
                    </div>
					<el-form label-position="top" ref="loginForm2" :model="loginForm2" :rules="loginRules2" label-width="60px">
                        <input type="password" class="inputhide"/>
                        <input type="text" class="inputhide"/>
						<el-form-item label="用户名" prop="account">
							<div class="con">
								<el-input type="text" v-model="loginForm2.account" placeholder="邮箱或手机号码"></el-input>
							</div>
						</el-form-item>
						<el-form-item label="验证码" prop="code">
							<div class="con">
								<el-input v-model="loginForm2.code" placeholder="请填写验证码"></el-input>
								<div class="get-code" @click="getCode(loginForm2.account,2)">{{ getSmsCodeText1 }}</div>
							</div>
						</el-form-item>
                        <el-form-item label="新密码" prop="password">
                            <div class="con">
                                <el-input type="password" v-model="loginForm2.password" placeholder="请填写新密码" show-password></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="password1">
                            <div class="con">
                                <el-input type="password" v-model="loginForm2.password1" placeholder="请确认密码" show-password></el-input>
                            </div>
                        </el-form-item>
                    </el-form>

                    <div class="btn-group">
                        <div class="btn" @click="retrievePassword">找回密码</div>
                        <div class="btn white" @click="show = 1">返回登录</div>
                    </div>
                </template>

            </div>
        </div>
    </div>
</template>

<script>

import {emailVerificationCode, logintoken, smsVerificationCode,forgetPassword} from '@/api/login';
import {userDetail} from "@/api/account";

export default {
    name: "login",
    data() {
        return {
          loginForm: {
            account: '',
            password: '',
            type: '',
          },
          loginForm1: {
            account: '',
            code: '',
          },
          loginForm2: {
            account: '',
            code: '',
			  password: '',
			  password1: '',
          },
          loginRules: {
            account: [{required: true, trigger: 'blur', message: '必填项'}],
            password: [{required: true, trigger: 'blur', message: '必填项'}],
          },
          loginRules1: {
            account: [{required: true, trigger: 'blur', message: '必填项'}],
            code: [{required: true, trigger: 'blur', message: '必填项'}],
          },
			loginRules2: {
				account: [{required: true, trigger: 'blur', message: '必填项'}],
				code: [{required: true, trigger: 'blur', message: '必填项'}],
				password: [{required: true, trigger: 'blur', message: '必填项'}],
				password1: [{required: true, trigger: 'blur', message: '必填项'}],
			},
          checked: false, // 是否记住账号密码
			disabled:false, // 未获取验证码
			disabled1:false, // 未获取验证码
			time:0,
			time1:0,
          second:60,

            show: 1
        }
    },
    computed: {
      //获取验证码登录验证码文字
      getSmsCodeText: function() {
        return this.time > 0 ? this.time + 'S后重新获取' : '获取验证码';
      },
		//获取忘记密码验证码文字
		getSmsCodeText1: function() {
			return this.time1 > 0 ? this.time1 + 'S后重新获取' : '获取验证码';
		},
    },
    mounted(){
      // console.log( new Date().getTime() ) //获取token的当前时间
      if(localStorage.getItem('accountPass')){ // 获取缓存的账密
        let accountPass=JSON.parse(localStorage.getItem('accountPass'))
        this.loginForm.account=accountPass.account
        this.loginForm.password=accountPass.password
        this.checked=true
      }
    },
    methods:{
		isphone(phone){
			let isphone=true
			if(!(/^1[3456789]\d{9}$/.test(phone))){
				this.$message({
					type: 'warning',
					message: '请填写正确的手机号！',
					duration: 1500
				});
				isphone=false
			}
			return isphone
		},

      //获取验证码倒计时
      run: function() {
        this.time = this.second;
        this.timer();
      },
      stop: function(){
        this.time = 0;
        this.disabled = false;
      },
      timer: function() {
        if (this.time > 0) {
          this.time--;
          this.disabled = true
          setTimeout(this.timer, 1000);
        }else{
          this.stop()
        }
      },
		// 忘记密码验证码倒计时
		run1: function() {
			this.time1 = this.second;
			this.timer1();
		},
		stop1: function(){
			this.time1 = 0;
			this.disabled1 = false;
		},
		timer1: function() {
			if (this.time1 > 0) {
				this.time1--;
				this.disabled1 = true
				setTimeout(this.timer1, 1000);
			}else{
				this.stop1()
			}
		},

		gocodelogin(formName){
        // this.$refs[formName].resetFields(); // 重置表单
        // this.loginForm1={
        //   account: '',
        //   code: '',
        // }
        this.show = 2
      },
      retpass(){
        this.loginForm2={
          account: '',
          code: '',
        }
        this.show = 3
      },

	//	--------------接口请求
	//	忘记密码
		retrievePassword(){
		    console.log(11)
            var params = {
                // refreshToken: localStorage.getItem('refresh_Token'),
                account: this.loginForm2.account,
                verificationCode: this.loginForm2.code,
                password: this.loginForm2.password,
                password1: this.loginForm2.password1,
            };
            let isphone=/^1[3456789]\d{9}$/.test(this.loginForm2.account)
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(this.loginForm2.account)
            if(!isphone && !mailReg){ // 既不是手机号也不是邮箱
                this.$message({
                    showClose: true,
                    message: '请填写正确的账号格式！',
                    type: 'warning'
                });
            }else {
                this.$refs.loginForm2.validate(valid => {
                    if (valid) {
                        forgetPassword(params).then((res) => {
                            if (res.data.code==200) {
                                console.log(res.data)
                                this.$message({
                                    type: 'success',
                                    message: res.data.message,
                                    duration: 2000
                                });
                                this.show=1
                            }else{
                                this.$message({
                                    type: 'error',
                                    message: res.data.message,
                                    duration: 2000
                                });
                            }
                            // localStorage.setItem('type', res.data.data);

                        })
                    }
                })
            }
		},
	//	验证码登录
		codelogin(){ // 验证码登录
			var params = {
				// refreshToken: localStorage.getItem('refresh_Token'),
				account: this.loginForm1.account,
                password: this.loginForm1.code,
				grantType:"sms_code",
				tenantId:"000000",
			};
            let isphone=/^1[3456789]\d{9}$/.test(this.loginForm1.account)
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(this.loginForm1.account)
            if(!isphone && !mailReg){ // 既不是手机号也不是邮箱
                this.$message({
                    showClose: true,
                    message: '请填写正确的账号格式！',
                    type: 'warning'
                });
            }else {
                if (mailReg) { // 邮箱
                    params.grantType='email_code'
                }
                this.$refs.loginForm1.validate(valid => {
                    if (valid) {
                        logintoken(params).then((res) => {
                            if (res.data.code==200) {
                                this.loginIn(res.data.data) // 登录成功后
                            }else{
                                this.$message({
                                    type: 'error',
                                    message: res.data.message,
                                    duration: 2000
                                });
                            }
                            // localStorage.setItem('type', res.data.data);

                        })
                    }
                })
            }
			console.log('登录')

		},
        // 登录成功后
        loginIn(data){
            this.$message({
                type: 'success',
                message: "登录成功",
                duration: 2000
            });
            localStorage.setItem('userInfo', JSON.stringify(data)); // 登录用户信息
            localStorage.setItem('Token', data.accessToken); // 令牌
            localStorage.setItem('refresh_Token', data.refreshToken); //刷新令牌
            localStorage.setItem('expires_in', data.expiresIn); //过期时间
            localStorage.setItem('expires_at', new Date().getTime() + data.expiresIn * 1000); //获取token的当前时间

            this.getuserDetail() // 获取客户信息


			// if(this.$route.path=='/home' || this.$route.path=='/SearchResult'){ // 当前路由是首页 或者搜索页
            //     window.location.reload(); //刷新页面
            // }
            // this.$router.push({ path: '/refresh' })  //刷新当前路由  重新获取动态路由
        },
		//账密登录
		login(type){
			// return
			var params = {
				// refreshToken: localStorage.getItem('refresh_Token'),
				account: this.loginForm.account,
				password: this.loginForm.password,
				grantType:"password",
				tenantId:"000000",
			};
			console.log('登录')
			this.$refs.loginForm.validate(valid => {
				if (valid) {
					if(type==1 && this.checked){ // 账密登录 并勾选了记住
						localStorage.setItem('accountPass', JSON.stringify(params)); // 用户信息
					}else{
					    if(!this.checked){
                            localStorage.removeItem('accountPass')
                        }
                    }
					logintoken(params).then((res) => {

						if (res.data.code==200) {
                            this.loginIn(res.data.data) // 登录成功后
						}else{
							this.$message({
								type: 'error',
								message: res.data.message,
								duration: 2000
							});
						}
						// localStorage.setItem('type', res.data.data);

					})
				}
			})
		},
		close:function () {
			this.$emit('close')
		},

		//获取验证码
		getCode(phone,type){
			if(this.disabled) return
            let apiUrl=smsVerificationCode
            let params = {
                phone: phone,
            }
            let isphone=/^1[3456789]\d{9}$/.test(phone)
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(phone)
            if(!isphone && !mailReg){ // 既不是手机号也不是邮箱
                this.$message({
                    showClose: true,
                    message: '请填写正确的账号格式！',
                    type: 'warning'
                });
            }else{
                if(mailReg){ // 邮箱
                    apiUrl=emailVerificationCode
                    params = {
                        email: phone,
                    }
                }
                apiUrl(params).then((res) => {
                    if (res.data.code == '200') {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        if(type==1){ // 验证码登录验证码
                            this.loginForm1.code=res.data.data.verificationCode
                            this.run()
                        }else{ // 忘记密码验证码
                            this.loginForm2.code=res.data.data.verificationCode
                            this.run1()
                        }
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                })

			}

		},

		getuserDetail(){ // 获取用户信息
			console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
			let userId=JSON.parse(localStorage.getItem('userInfo')).userId
			userDetail({userId:userId}).then((res) => {
				if (res.data.code==200) {
                    localStorage.setItem('customInfo', JSON.stringify(res.data.data)); // 登录用户信息
                    localStorage.setItem('customId', res.data.data.id)
                    // debugger
					console.log(this.$route)
                    this.$emit('islogin',true) // 关闭登录弹窗 重新获取消息通知数量
                    if(this.$route.path=='/register'){ // 注册页登录后跳首页
	                    this.$router.push({path: '/home'}) // 去首页
                    }
					window.location.reload(); //刷新页面

                }

			})
		},

    }
}
</script>

<style lang="scss">
.gozc{
	a:hover{
		color: #f37500 !important;
	}
}

/*禁止自动填充表单*/
.inputhide{
  width: 0;
  border: none;
  position: absolute
}
</style>
<style scoped>

</style>
