<template>
    <div class="page-register">
        <div class="wp">
            <div class="login-register-form" v-if="isshowTerm">
                <div class="btn" style="width: 50px;font-size: 14px;height: 30px;margin-top: -20px" @click="isshowTerm = false">返回</div>
                <div class="mat20" v-html="information.contentText"></div>
            </div>

            <div class="login-register-form" v-else>
                <div class="title">
                    <h3>用户注册</h3>
                    <p>已有账户，
                        <a href="javascript:;" @click="showPop = true">直接登录</a>
                    </p>
                </div>
                <div class="tips">
                    <p>您好! 您可以通过填写以下信息，创建专属于您的个人账户，获取您所在单位的专属服务</p>
                    <span>
                    星号<em>*</em>为必填项
                </span>
                </div>
                <div class="tit">账号</div>
                <el-form label-position="top" ref="registerForm" :model="registerForm" :rules="registerRules" label-width="60px">
					<input type="password" class="inputhide"/>
                    <input type="text" class="inputhide"/>
                    <el-form-item label="用户名" prop="phone">
                        <div class="con">
                            <el-input v-model="registerForm.phone" placeholder="邮箱或手机号码"></el-input>
<!--                            <el-autocomplete-->
<!--                                class="inline-input"-->
<!--                                v-model="registerForm.phone"-->
<!--                                :fetch-suggestions="querySearch"-->
<!--                                placeholder="邮箱或手机号码"-->
<!--                                :trigger-on-focus="false"-->
<!--                                @select="handleSelect"-->
<!--                            ></el-autocomplete>-->
                        </div>
                        <div class="tip">请填写有效电子邮箱或手机号码</div>
                    </el-form-item>
                    <el-form-item label="验证码" prop="code">
                        <div class="con">
                            <el-input v-model="registerForm.code" placeholder="请填写验证码"></el-input>
							<div class="get-code" @click="getCode">{{ getSmsCodeText }}</div>
                        </div>
                        <div class="tip">填写验证码</div>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <div class="con">
                            <el-input type="password" v-model="registerForm.password" placeholder="请输入密码" show-password></el-input>
                        </div>
                        <div class="tip">密码不能低于8位，且包含数字、大写字母、小写字母</div>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="password1">
                        <div class="con">
                            <el-input type="password" v-model="registerForm.password1" placeholder="请确认密码" show-password></el-input>
                        </div>
                        <div class="tip">再次输入密码</div>
                    </el-form-item>
                    <el-form-item label="">
                        <el-checkbox label="" v-model="checked">我已阅读并同意
                            <span @click.stop="isshowTerm=true" style="color: #df0b0b;text-decoration: underline">《一方科技网站条款》</span>
                        </el-checkbox>
                    </el-form-item>
                </el-form>
                <div class="btn" @click="register">立即注册</div>
            </div>

        </div>
        <login v-if="showPop" @close="showPop = false" />
    </div>
</template>

<script>
import login from "@/components/login.vue";
import {register,smsVerificationCode,emailVerificationCode} from "@/api/login";
import {websiteConfigDetail} from "@/api/home";
import Bus from "@/utils/bus";
export default {
    name: "Register",
    components:{login},
    data() {
        return {
            isshowTerm:false, // 是否显示条款
            information:'', // 条款信息

			registerForm:{
                code:"",
                phone:"",
				password:"",
				password1:"",
                // phone:"17888888888",
                // password:"l1111111",
                // password1:"l1111111",
			},
			registerRules: {
				phone: [{required: true, trigger: 'blur', message: '必填项'}],
				code: [{required: true, trigger: 'blur', message: '必填项'}],
				password: [{required: true, trigger: 'blur', message: '必填项'}],
				password1: [{required: true, trigger: 'blur', message: '必填项'}],
			},
			checked:false,
            showPop:false,

			disabled:false, // 未获取验证码
			time:0,
			second:60,

            restaurants:[
                { "value": "@163.com"},
                { "value": "@126.com"},
                { "value": "@qq.com"},
                { "value": "@gmail.com"},
                { "value": "@yeah.net"},
                { "value": "@sina.com"},
                { "value": "@139.com"},
                { "value": "@hotmail.com"},
                { "value": "@189.com"},
                { "value": "@outlook.com"},
                { "value": "@sohu.com"},
                { "value": "@sina.cn"},
            ], //
        }
    },
	computed: {
		//获取验证码文字
		getSmsCodeText: function() {
			return this.time > 0 ? this.time + 'S后重新获取' : '获取验证码';
		}
	},
    mounted() {
        this.getwebsiteConfigDetail() // 条款信息
        },
    methods:{
        // 选择邮箱
        handleSelect(item) {
            console.log(item);
            // this.registerForm.phone=this.registerForm.phone+item.value
        },
        querySearch(queryString, cb) {
            var restaurants = JSON.parse(JSON.stringify(this.restaurants));
            restaurants.forEach(i=>{
                i.value= this.registerForm.phone + i.value
            })
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },

        isphone(){
            let phone=true
            if(!(/^1[3456789]\d{9}$/.test(this.registerForm.phone))){
                this.$message({
                    type: 'warning',
                    message: '请填写正确的账号格式！',
                    duration: 1500
                });
                phone=false
            }
            return phone
        },
		register(){
			var params = {
				// refreshToken: localStorage.getItem('refresh_Token'),
				verificationCode: this.registerForm.code,
				password: this.registerForm.password,
                // phone:'',
                // email:'',
                // name:'111'
			};
            let isphone=/^1[3456789]\d{9}$/.test(this.registerForm.phone)
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(this.registerForm.phone)
            if(!isphone && !mailReg){ // 既不是手机号也不是邮箱
                this.$message({
                    showClose: true,
                    message: '请填写正确的账号格式！',
                    type: 'warning'
                });
            }else {
                if (mailReg) { // 邮箱
                    params.email=this.registerForm.phone
                }else{
                    params.phone=this.registerForm.phone
                }
                this.$refs.registerForm.validate(valid => {
                    if (valid) {
                        var re =/^(?=.*[a-z])(?=.*\d)[^]{8,16}$/;
                        var result =  re.test(this.registerForm.password);
                        if(!result) {
                            this.$message({
                                type: 'warning',
                                message: "密码必须包含数字,字母,且不少于8位！",
                                duration: 2000
                            });
                            return
                        }
                        if(this.registerForm.password != this.registerForm.password1){
                            this.$message({
                                type: 'warning',
                                message: "两次密码不一致！",
                                duration: 2000
                            });
                            return
                        }
                        if (!this.checked) { // 账密登录 并勾选了记住
                            this.$message({
                                type: 'warning',
                                message: "请阅读并同意网站条款！",
                                duration: 2000
                            });
                            return
                        }
                        register(params).then((res) => {
                            console.log(res)
                            if (res.data.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: "注册成功",
                                    duration: 2000
                                });
                                Bus.$emit('re-login', '未登录')
                                // this.$router.push('/home')
                            }else{
                                this.$message({
                                    type: 'error',
                                    message: res.data.message,
                                    duration: 2000
                                });
                            }
                        }).catch(error=>{
                            // console.log(error.response.data)
                            if(error.response.data.code !=200){
                                this.$message({
                                    type: 'error',
                                    message: error.response.data.message,
                                    duration: 2000
                                });
                            }
                        })
                    }
                })
            }
			console.log('注册')
            // if(!this.isphone()) return //手机号格式不对


		},

		//获取验证码
		getCode(){
			if(this.disabled) return
			// this.run()
            // if(!this.isphone()) return
            let apiUrl=smsVerificationCode
            let params = {
                phone: this.registerForm.phone,
            }
            let isphone=/^1[3456789]\d{9}$/.test(this.registerForm.phone)
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(this.registerForm.phone)
            if(!isphone && !mailReg){ // 既不是手机号也不是邮箱
                this.$message({
                    showClose: true,
                    message: '请填写正确的账号格式！',
                    type: 'warning'
                });
            }else{
                if(mailReg){ // 邮箱
                    apiUrl=emailVerificationCode
                    params = {
                        email: this.registerForm.phone,
                    }
                }
                apiUrl(params).then((res) => {
                    if (res.data.code == '200') {
                        this.registerForm.code=res.data.data.verificationCode
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.run()
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                })

            }




		},
		//获取验证码倒计时
		run: function() {
			this.time = this.second;
			this.timer();
		},
		stop: function(){
			this.time = 0;
			this.disabled = false;
		},
		timer: function() {
			if (this.time > 0) {
				this.time--;
				this.disabled = true
				setTimeout(this.timer, 1000);
			}else{
				this.stop()
			}
		},

    //   调用接口
        getwebsiteConfigDetail(){ // 获取基础设置 网站条款
            websiteConfigDetail({tag:'website_terms'}).then((res) => {
                if (res.data.code==200) {
                    this.information=res.data.data
                    this.information.contentText=this.$utils.showHtml(this.information.contentText)
                }else{

                }

            })
        },



	},

}
</script>

<style scoped>
.inputhide{
	width: 0;
	border: none;
	position: absolute
}

</style>
